<template>
    <ion-page data-pageid="tab2">
        <ion-toolbar
            style="--background: #03a9f4; color: white; --min-height: 36px"
            class="custom-toolbar"
        >
            <div class="row align-center">
                <slot name="before">
                    <div class="shrink">
                        <!-- <DxButton
                            type="default"
                            styling-mode="outlined"
                            icon="mdi mdi-chevron-down"
                            class="ml-1"
                        /> -->
                        <DxButton
                            type="default"
                            icon="mdi mdi-help-circle-outline"
                            class="ml-1"
                            style="opacity: 0"
                        />
                        <DxButton
                            type="default"
                            icon="mdi mdi-help-circle-outline"
                            class="ml-1"
                            style="opacity: 0"
                        />
                    </div>
                </slot>
                <slot name="center">
                    <div class="grow text-xs-center">DANH SÁCH CHUYẾN ĐI</div>
                </slot>
                <slot name="after">
                    <div class="shrink">
                        <DxButton
                            icon="mdi mdi-filter-variant"
                            type="default"
                            @click="
                                () => {
                                    $store.commit('NhanVienBanVe/Set', {
                                        key: 'ProcessGetDiemXuatPhat',
                                        data: false,
                                    });
                                    $store.commit('NhanVienBanVe/Set', {
                                        key: 'ProcessGetDiemDen',
                                        data: false,
                                    });
                                    PopupLocDuLieu = true;
                                }
                            "
                        />
                        <DxButton
                            v-if="
                                $Core.AuthApi.TokenParsed.preferred_username != '0888888888'
                            "
                            type="default"
                            icon="mdi mdi-help-circle-outline"
                            class="ml-1"
                            @click="MoTrangHuongDan"
                        />
                    </div>
                </slot>
            </div>
        </ion-toolbar>

        <ion-content :scroll-events="false">
            <div class="row mb-2 frame-sap-xep">
                <SapXepVue />
            </div>

            <div class="row px-2">
                <div class="xs12">
                    <div class="row align-center color-067">
                        <div class="shrink">
                            <div class="row align-center">
                                <i class="mdi mdi-calendar font-20 mr-1"></i>
                                {{ NgayKhoiHanh ? $Helper.formatDate(NgayKhoiHanh) : "" }}
                                <i class="mdi mdi-map-marker font-20 ml-2 mr-1"></i>
                            </div>
                        </div>
                        <div
                            class="grow"
                            style="
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            "
                        >
                            <div class="row align-center">
                                {{ DiemDen ? DiemDen.diaChi : "" }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row px-2">
                <div class="xs12 danh-sach-chuyen-di">
                    <DanhSachChuyenDiVue />
                </div>
            </div>
        </ion-content>

        <DxSpeedDialAction
            :visible="hiddenFloatBtn"
            :index="1"
            icon="mdi mdi-ticket-confirmation"
            :elementAttr="{
                class: 'float-btn-bao-cao-ban-ve',
            }"
            label="Báo cáo bán vé"
            @click="
                hiddenFloatBtn = false;
                $router.push('/Bao-Cao/Bao-Cao-Ban-Ve');
            "
        />

        <TempPopupVue
            v-model:dialog="PopupLocDuLieu"
            height="auto"
            maxHeight="80%"
            class="popup-padding-16"
        >
            <template #content>
                <PopupTimKiemChuyenDiVue @hiddenPopup="hiddenPopup" />
            </template>
        </TempPopupVue>
    </ion-page>
</template>

<script>
import config from "devextreme/core/config";
import repaintFloatingActionButton from "devextreme/ui/speed_dial_action/repaint_floating_action_button";
import {
    IonContent,
    IonPage,
    IonToolbar,
    onIonViewWillEnter,
    onIonViewWillLeave,
} from "@ionic/vue";
import { DxButton, DxSpeedDialAction } from "devextreme-vue";
import PopupTimKiemChuyenDiVue from "./components/PopupTimKiemChuyenDi.vue";
import TempPopupVue from "../../components/_Common/TempPopup.vue";
import SapXepVue from "./components/SapXep.vue";
import DanhSachChuyenDiVue from "./components/DanhSachChuyenDi.vue";
export default {
    components: {
        IonContent,
        IonPage,
        DxButton,
        IonToolbar,
        PopupTimKiemChuyenDiVue,
        TempPopupVue,
        SapXepVue,
        DanhSachChuyenDiVue,
        DxSpeedDialAction,
    },
    data() {
        return {
            PopupLocDuLieu: false,
            hiddenFloatBtn: true,
        };
    },
    computed: {
        DiemDen() {
            return this.$store.state.NhanVienBanVe.DiemDen;
        },
        NgayKhoiHanh() {
            return this.$store.state.NhanVienBanVe.NgayTimKiem;
        },
        BanVeThanhCong() {
            return this.$store.state.NhanVienBanVe.BanVeThanhCong;
        },
        VeTrangChu() {
            return this.$store.state.NhanVienBanVe.VeTrangChu;
        },
    },
    methods: {
        hiddenPopup() {
            this.PopupLocDuLieu = false;
        },
        MoTrangHuongDan() {
            this.$router.push({
                path: "/huong-dan-su-dung/Xem-Chi-Tiet-Huong-Dan",
                query: {
                    name: this.$i18n.t("UrlHuongDan.BanVeUyThacChoNhanVien"),
                },
            });
        },
    },
    mounted() {
        config({
            floatingActionButtonConfig: {
                position: {
                    at: "right bottom",
                    my: "right bottom",
                    offset: "-16 -44",
                },
                shading: true,
            },
        });
        repaintFloatingActionButton();
    },
    created() {
        onIonViewWillEnter(() => {
            if (this.VeTrangChu) {
                this.$store.commit("NhanVienBanVe/Set", {
                    key: "VeTrangChu",
                    data: false,
                });
                return this.$router.push("/tabs/tab1");
            }
            this.hiddenFloatBtn = true;
            let arrEvent = this.emitter.all;
            for (const [key, value] of arrEvent) {
                if (key == "hiddenFloatBtn") {
                    this.emitter.off("hiddenFloatBtn");
                }
            }
            this.emitter.on("hiddenFloatBtn", () => {
                this.hiddenFloatBtn = false;
            });
            if (this.BanVeThanhCong) {
                this.emitter.emit("TimKiemChuyenDi");
                this.$store.commit("NhanVienBanVe/Set", {
                    key: "BanVeThanhCong",
                    data: false,
                });
            }
        });
        onIonViewWillLeave(() => {
            this.hiddenFloatBtn = false;
        });
    },
};
</script>

<style scoped>
.frame-sap-xep {
    height: 48px;
    border-bottom: 1px solid #dadce0;
    box-shadow: 0px 0px 10px 1px #dadce0;
}
.danh-sach-chuyen-di {
    height: calc(100vh - 36px - 36px - 48px - 44px);
}
</style>

<style>
.float-btn-bao-cao-ban-ve.dx-overlay.dx-fa-button.dx-fa-button-main
    .dx-overlay-wrapper
    .dx-overlay-content {
    max-height: 40px;
}
</style>
