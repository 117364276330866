<template>
    <!-- group: { selector: 'NgayXuatBenDuKien', desc: false }, -->
    <DxList
        :data-source="{
            store: DanhSachChuyenDi,
        }"
        class="list-chuyen-di-ban-ve"
        height="100%"
        :onItemClick="onItemClick"
    >
        <!-- :collapsible-groups="true"
        :grouped="true"
        <template #group="{ data: item }">
            <div class="font-16 font-medium" @click="onItemClick(item)">
                {{ item.key ? $Helper.formatDate(item.key) : "Ngày" }}
            </div>
        </template> -->
        <template #item="{ data: item }">
            <ItemChuyenDiVue :item="item" />
        </template>
    </DxList>
</template>

<script>
import { DxList } from "devextreme-vue";
import { mapMutations } from "vuex";
import ItemChuyenDiVue from "./ItemChuyenDi.vue";
export default {
    components: {
        DxList,
        ItemChuyenDiVue,
    },
    data() {
        return {
            DanhSachChuyenDi: [],
        };
    },
    methods: {
        ...mapMutations("ChuyenDi", ["Set"]),
        ToastThongBao(type, msg) {
            let toast = {
                type: type,
                message: msg,
                visible: true,
            };
            this.emitter.emit("onToast", toast);
        },
        async onItemClick(e) {
            if (!e.itemData.coTheMuaVe) {
                this.ToastThongBao(
                    "error",
                    "Bạn không được phép bán vé cho chuyến đi này!",
                );
                return;
            }
            try {
                this.$startLoading;
                // let rs = await this.$store.dispatch(
                //     "NhanVienBanVe/KiemTraTrangThaiChuyenDi",
                //     e.itemData.id
                // );
                // if (rs.status && rs.data) {
                this.Set({ key: "reloadData", data: true });
                this.emitter.emit("hiddenFloatBtn");
                this.$router.push({
                    path: "/Ban-Ve/Chon-Cho",
                    query: {
                        maChuyenDi: e.itemData.id,
                        idDoanhNghiep: e.itemData.doanhNghiep_Id,
                        guidLoTrinh: e.itemData.loTrinh.idLoTrinh,
                        bienKiemSoat: e.itemData.xe_BienSoXe,
                        gioXuatBenDuKien: e.itemData.gioXuatBenDuKien,
                    },
                });
                // } else {
                //     this.ToastThongBao("error", rs.message);
                // }
                this.$stopLoading;
            } catch (error) {
                this.$stopLoading;
                console.log("🚀 ~ error", error);
            }
        },
        async TimKiemChuyenDi() {
            try {
                this.DanhSachChuyenDi = (
                    await this.$store.dispatch("NhanVienBanVe/LayDanhSachChuyenDi")
                ).Store;
            } catch (error) {
                console.log("🚀 ~ error", error);
            }
        },
    },
    mounted() {
        let self = this;
        let arrEvent = this.emitter.all;
        for (const [key, value] of arrEvent) {
            if (key == "TimKiemChuyenDi") {
                this.emitter.off("TimKiemChuyenDi");
            }
        }
        this.emitter.on("TimKiemChuyenDi", () => {
            this.TimKiemChuyenDi();
        });
    },
};
</script>

<style>
.list-chuyen-di-ban-ve .dx-item.dx-list-item {
    overflow: unset;
    margin-bottom: 12px;
}
.list-chuyen-di-ban-ve .dx-list-group-body {
    max-height: calc(100vh - 36px - 36px - 48px - 44px - 40px);
    overflow: auto;
}
.list-chuyen-di-ban-ve .dx-list-group-header {
    color: #fb8c00;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    flex-direction: row-reverse;
    padding: 8px 8px 4px;
    border: 1px solid #dadce0;
    border-radius: 4px;
}
</style>
