<template>
    <div class="frame-chuyen-di">
        <div class="row justify-center font-20 font-medium">
            {{ $Helper.formatDate(item.ngayXuatBenDuKien) }}
        </div>
        <div
            class="row"
            style="border-bottom: 1px solid #dadce0; padding-bottom: 4px"
        >
            <div class="xs4">
                <div class="row justify-center font-24 color-primary">
                    {{
                        item.gioXuatBenDuKien
                            ? $Helper.formatTime(item.gioXuatBenDuKien)
                            : null
                    }}
                </div>
                <div
                    class="row font-14 font-medium justify-center"
                    style="white-space: break-spaces; text-align: center"
                >
                    {{ item.loTrinh.diemXuatPhat.tenDiemDung }}
                </div>
            </div>
            <div class="xs4 ke-ngang-tuyen">
                <i class="mdi mdi-circle-slice-8 font-16 color-success"></i
                >------------<i
                    class="mdi mdi-circle-slice-8 font-16 color-error"
                ></i>
            </div>
            <div class="xs4">
                <div class="row justify-center font-24 color-primary">
                    {{
                        item.gioDenNoiDuKien
                            ? $Helper.formatTime(item.gioDenNoiDuKien)
                            : null
                    }}
                </div>
                <div
                    class="row font-14 font-medium justify-center"
                    style="white-space: break-spaces; text-align: center"
                >
                    {{ item.loTrinh.diemDung.tenDiemDung }}
                </div>
            </div>
        </div>

        <div class="row pt-2 px-1 mb-1 align-center justify-space-between">
            <div class="grow font-20 font-medium color-087">
                {{ item.xe_BienSoXe }}
            </div>
            <div
                class="
                    shrink
                    ml-3
                    text-xs-right
                    font-20 font-medium
                    color-success
                "
            >
                {{ $MoneyFormat(item.giaVeMin) }}đ
            </div>
        </div>
        <div class="row px-1 pb-2">
            <div class="grow">
                Số ghế | Số giường: {{ item.soChoNgoi }} |
                {{ item.soGiuongNam }}
            </div>
            <div class="shrink text-xs-right color-primary">
                Số chỗ trống: {{ item.soGheNgoiTrong + item.soGiuongNamTrong }}
            </div>
        </div>
        <div class="row px-1 pt-2" style="border-top: 1px solid #dadce0">
            <div class="xs12" style="white-space: pre-wrap">
                {{ item.hanhTrinhChay }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        item: { type: Object, default: () => {} },
    },
};
</script>

<style scoped>
.frame-chuyen-di {
    box-shadow: 0px 1px 5px #dadce0;
    padding: 12px 8px;
    border: 1px solid #dadce0;
    margin-top: 8px;
    border-radius: 8px;
}
.ke-ngang-tuyen {
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 1.2px;
}
</style>