<template>
    <div class="row mb-2 justify-center font-20 font-medium">
        Tìm kiếm chuyến đi
    </div>

    <div class="content-popup pb-3">
        <DxValidationGroup ref="formValidation">
            <div class="row">Ngày khởi hành</div>
            <div class="row mb-4">
                <div class="xs12">
                    <DxDateBox
                        v-model="tmpNgayKhoiHanh"
                        displayFormat="dd/MM/yyyy"
                        :use-mask-behavior="true"
                        ref="NgayKhoiHanh"
                        dropDownButtonTemplate="customIcon"
                        validationMessageMode="always"
                        styling-mode="underlined"
                    >
                        <template #customIcon="{}">
                            <DxButton icon="mdi mdi-calendar" />
                        </template>
                    </DxDateBox>
                </div>
            </div>

            <div class="row">Bạn đi từ đâu</div>
            <div class="row mb-4">
                <div class="xs12">
                    <DxSelectBox
                        v-model:value="tmpDiemXuatPhat"
                        :dataSource="{
                            pageSize: 10,
                            paginate: true,
                            store: DanhSachDiemXuatPhat,
                        }"
                        ref="DanhSachDiemXuatPhat"
                        displayExpr="diaChi"
                        valueExpr="placemarkId"
                        placeholder="Nhập địa điểm"
                        validationMessageMode="always"
                        styling-mode="underlined"
                        :showClearButton="true"
                        :showDropDownButton="false"
                        :searchEnabled="true"
                    >
                        <DxValidator ref="validate">
                            <!-- <DxRequiredRule
                                message="Vui lòng nhập nơi bạn xuất phát!"
                            /> -->
                        </DxValidator>
                    </DxSelectBox>
                </div>
            </div>

            <div class="row">Bạn muốn đến đâu</div>
            <div class="row">
                <div class="xs12">
                    <DxSelectBox
                        v-model:value="tmpDiemDen"
                        :dataSource="{
                            pageSize: 10,
                            paginate: true,
                            store: DanhSachDiemDen,
                        }"
                        ref="DanhSachDiemDen"
                        displayExpr="diaChi"
                        valueExpr="placemarkId"
                        placeholder="Nhập địa điểm"
                        validationMessageMode="always"
                        styling-mode="underlined"
                        :showClearButton="true"
                        :showDropDownButton="false"
                        :searchEnabled="true"
                    >
                        <DxValidator ref="validate">
                            <!-- <DxRequiredRule
                                message="Vui lòng nhập nơi bạn muốn đến!"
                            /> -->
                        </DxValidator>
                    </DxSelectBox>
                </div>
            </div>
        </DxValidationGroup>
    </div>

    <div class="row justify-space-between">
        <DxButton
            text="Hủy"
            type="default"
            styling-mode="text"
            @click="hiddenPopup"
        />
        <DxButton
            text="Tìm kiếm"
            type="default"
            styling-mode="contained"
            @click="TimKiemChuyenDi()"
        />
    </div>
</template>

<script>
import { DxValidationGroup } from "devextreme-vue/validation-group";
import { DxValidator, DxRequiredRule } from "devextreme-vue/validator";
import { DxButton, DxDateBox, DxSelectBox } from "devextreme-vue";
export default {
    components: {
        DxValidationGroup,
        DxValidator,
        DxRequiredRule,
        DxDateBox,
        DxButton,
        DxSelectBox,
    },
    data() {
        return {
            DanhSachDiemDen: [],
            DanhSachDiemXuatPhat: [],
            tmpDiemDen: null,
            tmpDiemXuatPhat: null,
            tmpNgayKhoiHanh: new Date(),
            DaGanDuLieu: false,
            DaGanDuLieuXuatPhat: false,
            checkLoading: false,
        };
    },
    computed: {
        ProcessGetDiemDen() {
            return this.$store.state.NhanVienBanVe.ProcessGetDiemDen;
        },
        ProcessGetDiemXuatPhat() {
            return this.$store.state.NhanVienBanVe.ProcessGetDiemXuatPhat;
        },
        DiemDen: {
            get() {
                return this.$store.state.NhanVienBanVe.DiemDen;
            },
            set(data) {
                this.$store.commit("NhanVienBanVe/Set", {
                    key: "DiemDen",
                    data: data,
                });
            },
        },
        DiemXuatPhat: {
            get() {
                return this.$store.state.NhanVienBanVe.DiemXuatPhat;
            },
            set(data) {
                this.$store.commit("NhanVienBanVe/Set", {
                    key: "DiemXuatPhat",
                    data: data,
                });
            },
        },
        NgayKhoiHanh: {
            get() {
                return this.$store.state.NhanVienBanVe.NgayTimKiem;
            },
            set(data) {
                this.$store.commit("NhanVienBanVe/Set", {
                    key: "NgayTimKiem",
                    data: data,
                });
            },
        },
    },
    watch: {
        ProcessGetDiemDen: {
            handler: function () {
                // if (
                //     this.ProcessGetDiemDen &&
                //     !this.DaGanDuLieu &&
                //     this.DiemDen
                // ) {
                //     setTimeout(() => {
                //         this.tmpDiemDen = this.DiemDen
                //             ? this.DiemDen.placemarkId
                //             : null;
                //         setTimeout(() => {
                //             this.$refs.NgayKhoiHanh.instance.focus();
                //         }, 10);
                //         this.$stopLoading;
                //     }, 400);
                //     this.DaGanDuLieu = true;
                // } else {
                //     setTimeout(() => {
                //         this.$stopLoading;
                //     }, 1000);
                // }
            },
            deep: true,
            immediate: true,
        },
        ProcessGetDiemXuatPhat: {
            handler: function () {
                // if (
                //     this.ProcessGetDiemXuatPhat &&
                //     !this.DaGanDuLieuXuatPhat &&
                //     this.DiemXuatPhat
                // ) {
                //     setTimeout(() => {
                //         this.tmpDiemXuatPhat = this.DiemXuatPhat
                //             ? this.DiemXuatPhat.placemarkId
                //             : null;
                //         this.$stopLoading;
                //     }, 400);
                //     this.DaGanDuLieuXuatPhat = true;
                // } else {
                //     setTimeout(() => {
                //         this.$stopLoading;
                //     }, 1000);
                // }
            },
            deep: true,
            immediate: true,
        },
    },
    methods: {
        hiddenPopup(bool) {
            if (bool == false) {
                this.DiemDen = null;
                this.DiemXuatPhat = null;
                if (this.tmpDiemDen) {
                    let dataSource =
                        this.$refs.DanhSachDiemDen.instance.getDataSource();
                    if (dataSource) {
                        let arrItem = dataSource._items;
                        if (arrItem && arrItem.length) {
                            let item = arrItem.filter(
                                (e) => e.placemarkId == this.tmpDiemDen
                            );
                            if (item.length > 0) {
                                this.DiemDen = item[0];
                            }
                        }
                    }
                }
                if (this.tmpDiemXuatPhat) {
                    let dataSource =
                        this.$refs.DanhSachDiemXuatPhat.instance.getDataSource();
                    if (dataSource) {
                        let arrItem = dataSource._items;
                        if (arrItem && arrItem.length) {
                            let item = arrItem.filter(
                                (e) => e.placemarkId == this.tmpDiemXuatPhat
                            );
                            if (item.length > 0) {
                                this.DiemXuatPhat = item[0];
                            }
                        }
                    }
                }
                this.NgayKhoiHanh = this.tmpNgayKhoiHanh;
            } else {
                this.tmpDiemDen = null;
                this.tmpDiemXuatPhat = null;
                this.NgayKhoiHanh = new Date();
            }
            this.DanhSachDiemDen = [];
            this.DanhSachDiemXuatPhat = [];
            this.$emit("hiddenPopup");
        },
        async getData() {
            try {
                this.DanhSachDiemDen = (
                    await this.$store.dispatch(
                        "NhanVienBanVe/LayDanhSachDiemDen",
                        "placemarkId"
                    )
                ).Store;
                this.DanhSachDiemXuatPhat = (
                    await this.$store.dispatch(
                        "NhanVienBanVe/DanhSachDiemXuatPhat",
                        "placemarkId"
                    )
                ).Store;
            } catch (error) {
                console.log("🚀 ~ error", error);
            }
        },
        TimKiemChuyenDi() {
            let validate = this.$refs.formValidation.instance.validate();
            if (validate.isValid) {
                this.hiddenPopup(false);
                this.emitter.emit("TimKiemChuyenDi");
            } else {
                this.$Helper.autoFocusError(validate);
            }
        },
    },
    created() {
        this.DaGanDuLieu = false;
        this.DaGanDuLieuXuatPhat = false;
        this.tmpDiemDen = null;
        this.tmpDiemXuatPhat = null;
        this.tmpNgayKhoiHanh = this.NgayKhoiHanh
            ? this.NgayKhoiHanh
            : new Date();
        this.getData();
    },
    mounted() {
        if (this.DiemDen) {
            this.$startLoading;
            setTimeout(() => {
                let self = this;
                let elmDiemDen = self.$refs.DanhSachDiemDen.instance;
                elmDiemDen
                    .getDataSource()
                    .load()
                    .done(function (items) {
                        self.tmpDiemDen = self.DiemDen
                            ? self.DiemDen.placemarkId
                            : null;
                        setTimeout(() => {
                            let elmDiemXuatPhat =
                                self.$refs.DanhSachDiemXuatPhat.instance;
                            elmDiemXuatPhat
                                .getDataSource()
                                .load()
                                .done(function (items) {
                                    self.tmpDiemXuatPhat = self.DiemXuatPhat
                                        ? self.DiemXuatPhat.placemarkId
                                        : null;
                                    self.$stopLoading;
                                });
                        }, 100);
                    });
            }, 100);
        }
    },
};
</script>

<style scoped>
.content-popup {
    height: calc(100% - 64px);
}
</style>